/* :root {
  --custom-navLinks-color: #fff;
  --custom-white-color: #fff;
  --cutom-prime-color: #f7581e;
  --cutom-text-color: #eee;
  --cutom-bg-color: #fff;
  --cutom-links-color: #111;
  --custom-font-color: #111;
  --custom-border-color: rgba(0, 0, 0, .125);
  --custom-plan-card-bg : rgb(237, 237, 237);
  --custom-border-color : transparent;
  --custom-bg-hover: rgba(232, 61, 9, 0.635);
  --custom-box-shadow : 1px 1px  15px rgb(216, 216, 216);
  --body-bg : #eee;
}

body[them-data='dark'] {
  --custom-navLinks-color: #453773;
  --custom-white-color: #fff;
  --cutom-prime-color: #f7581e;
  --cutom-text-color: #0e0e0e;
  --cutom-bg-color: #0e0e0e;
  --body-bg : #0e0e0e;
  --cutom-links-color: #fff;
  --custom-font-color: #eeeeee;
  --custom-border-color: rgba(255, 255, 255, 0.125);
  --custom-plan-card-bg : rgba(128, 138, 138, 0.274);
  --custom-border-color : white;
  --custom-bg-hover: rgba(138, 132, 128, 0.486);
  --custom-box-shadow : none;
} */
.load{
    position: absolute;
    width:100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--cutom-bg-color); */
}
.loader {
    position: relative;
  }
  
  .outer,
  .middle,
  .inner {
    border: 3px solid transparent;
    border-top-color: var(--cutom-prime-color);
    border-right-color: var(--cutom-prime-color);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .outer {
    width: 4.5em;
    height: 4.5em;
    margin-left: -1.75em;
    margin-top: -1.75em;
    animation: spin 2s linear infinite;
  }
  
  .middle {
    width: 3.1em;
    height: 3.1em;
    margin-left: -1.05em;
    margin-top: -1.05em;
    animation: spin 1.75s linear reverse infinite;
  }
  
  .inner {
    width: 1.8em;
    height: 1.8em;
    margin-left: -0.4em;
    margin-top: -0.4em;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  